<template lang="pug">
  .sidebar-layout(:class="classes")
    .sidebar-layout__sidebar-wrapper
      sidebar
    .sidebar-layout__views-wrapper
      slot
</template>

<script>
import Sidebar from './Sidebar.vue'

export default {
  name: 'SidebarLayout',

  components: {
    Sidebar
  },

  computed: {
    isSidebarMinified () {
      return false
    },

    classes () {
      return {
        'sidebar-layout--minimized': this.isSidebarMinified
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
