<template lang="pug">
  .farm-layout
    sidebar-layout
      router-view
</template>

<script>
import SidebarLayout from '@/components/layouts/sidebar/SidebarLayout.vue'

export default {
  name: 'Farm',

  components: {
    SidebarLayout
  }
}
</script>

<style lang="scss">
</style>
